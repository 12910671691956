<template>
    <div>
        <feather-icon icon="InfoIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="detailEvent"/>
        <feather-icon icon="EditIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="editRecord"/>
        <feather-icon icon="TrashIcon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer"
                      @click="confirmDeleteRecord"/>
    </div>
</template>

<script>
export default {
    name: 'CellRendererActions',
    methods: {
        detailEvent() {
            this.$router.push('/user/detail-event?postId=' + this.params.data.id).catch(() => {
            })
        },
        editRecord() {
            this.$router.push(`/user/edit-event?branch_id=${this.params.data.organizationBranchId}&id=${this.params.data.id}`).catch(() => {
            })
        },

        confirmDeleteRecord() {
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: `Xác nhận xoá`,
                text: `Bạn có chắc là muốn xoá bảng tin này không ?`,
                accept: this.deleteRecord,
                acceptText: "Xoá",
                cancelText: "Huỷ"
            })
        },
        deleteRecord() {
            this.$vs.loading();
            this.$crm.post('/posts/delete?postId=' + this.params.data.id).then(() => {
                this.$vs.loading.close();
                this.showDeleteSuccess();
                this.$store.commit('UPDATE_NEWS', true);
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        showDeleteSuccess() {
            this.$vs.notify({
                color: 'success',
                text: 'Xoá thành công',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
            })
        }

    }
}
</script>

<style scoped>
</style>
