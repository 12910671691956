<template>
    <div id="page-camera-list">
        <vs-popup class="noti-setting" :title="this.$t('textCamera.settingNotify') + ' ' + this.nameCamera" :active.camel="openPopup" >
            <TabNotificationSetting @close-popup="closePopup" :camera-event-status-types="cameraEventStatusTypes" :list-number-camera-event-status-types="listNumberCameraEventStatusTypes">
            </TabNotificationSetting>
        </vs-popup>

    </div>
</template>

<script>
import TabNotificationSetting from "../../../../components/notification-setting/TabNotificationSetting";
export default {
    components: {
        TabNotificationSetting
    },
    data() {
        return {
          nameCamera: '',
        }
    },
    watch: {
        cameraEventStatusTypes: {
            handler: function (val) {
                this.cameraEventStatusTypes = val;
            },
            deep: true
        },
        listNumberCameraEventStatusTypes: {
            handler: function (val) {
                this.listNumberCameraEventStatusTypes = val;
            },
            deep: true
        }
    },
    props: {
        openPopup: {
            type: Boolean,
            default:false
        },
        closePopup: {
            type: Function
        },
        cameraId: {
            type: Number
        },
        organizationBranchId: {
            type: Number
        },
        cameraEventStatusTypes: {
            type: Array,
            default: []
        },
        listNumberCameraEventStatusTypes: {
            type: Array ,
            default: []
        }
    },
    created() {
    },
    methods: {
        onClickCloseButtonNotification() {
            this.openPopup = false;
            this.$emit('closePopupNotification')
        }
    },
    mounted(){
        const next =this.$children[0].$refs.btnclose;
        next.$el.addEventListener('click', this.onClickCloseButtonNotification, false);
        this.$eventBus.$on('notify-camera', ($event) => {
            if ($event) {
                this.nameCamera = $event.cameraName;
            }
        })
        this.$eventBus.$on('notify-camera-setting', ($event) => {
            if ($event) {
                this.nameCamera = $event.userInfo.username;
            }
        })

    }
}
</script>

<style>
.noti-setting .vs-popup{
    width: 1050px;
    height: auto;
}
</style>
