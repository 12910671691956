<template>
    <div v-if="isShowTab">
        <vs-tabs v-if="!isOragnizationSettings" v-model="activeTab" class="tab-action-btn-fill-conatiner">
            <vs-tab :label="item.label" v-for="item in cameraEventStatusTypes"
                    :key="item.value">
                <div class="tab-text">
                    <NotificationSetting :notification-setting="getNotificationSetting(item.value)"
                                         @notification-setting-data="notificationSettingData"
                                         :disabled-all-notification="!cameraEventNotificationSettingDTO.turnOn"
                    />
                </div>
            </vs-tab>
        </vs-tabs>
        <NotificationSetting v-if="isOragnizationSettings" :notification-setting="getNotificationSetting(0)"
                             @notification-setting-data="notificationSettingData"
                             :disabled-all-notification="!cameraEventNotificationSettingDTO.turnOn"
        />
        <div class="vx-row justify-center mt-5">
            <div class="vx-col mt-2 flex">
                <vs-checkbox v-model="cameraEventNotificationSettingDTO.turnOn"></vs-checkbox>
                <span class="mt-2 flex">{{$t('menuCamera.receive')}}</span>
            </div>
            <div class="vx-col mt-1">
                <vx-tooltip :title="$t('textCamera.instructionsNotification')"
                            color="warning"
                            :text="$t('textCamera.textInstructionNotification')">
                    <feather-icon class="mr-5" color="primary"
                                  type="border"
                                  icon-pack="feather"
                                  icon="HelpCircleIcon"
                                  style="color: #1aaa55"
                    >
                    </feather-icon>
                </vx-tooltip>

            </div>
        </div>

        <!-- Save & Reset Button -->
        <div class="vx-row">
            <div class="vx-col w-full">
                <div class="mt-2 flex flex-wrap items-center justify-end">
                    <vs-button class="ml-auto" @click="saveChanges">{{isUpdate ? $t('button.update') : $t('button.create')}}
                    </vs-button>
                    <vs-button class="ml-4" type="border" color="warning" @click="close">{{$t('button.close')}}
                    </vs-button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import NotificationSetting from "./NotificationSetting"

const CLOSE_POPUP = 'close-popup';

const EVENT_NAME = 'camera-setting-';

export default {
    name: "TabNotificationSetting",
    data() {
        return {
            checkBoxValue:false,
            isUpdate: false,
            isShowTab: false,
            cameraEventNotificationSettingDTO: {
                itemCameraEventNotificationSettingDTOS: [],
                organizationBranchId: null,
                turnOn: true
            },
            activeTab: 0,
            cameraId: null,
            organizationBranchId: null,
            isOragnizationSettings: false
        }
    },
    computed: {

    },
    components: {NotificationSetting},
    props: {
        cameraEventStatusTypes: {
            type: Array,
            default: [],
        },
        listNumberCameraEventStatusTypes: {
            type: Array,
            default: [],
        },
    },
    methods: {
        getNotificationSetting(cameraEventStatusType) {
            if(!this.cameraEventNotificationSettingDTO.itemCameraEventNotificationSettingDTOS.some(x => x.cameraEventStatusType == cameraEventStatusType)){
                this.cameraEventNotificationSettingDTO.itemCameraEventNotificationSettingDTOS.push({
                    "cameraEventStatusType": cameraEventStatusType,
                    "cameraEventNotificationTypes": [],
                    "emailList": [],
                    "phoneNumberList": [
                    ],
                    "timeNotification": {
                        "arr0": [],
                        "arr1": [],
                        "arr2": [],
                        "arr3": [],
                        "arr4": [],
                        "arr5": [],
                        "arr6": []
                    },
                    "periodNotify": 3,
                    "always": false,
                    "nameEventBusUse": `camera-setting-${cameraEventStatusType}`
                })
            }
            return this.cameraEventNotificationSettingDTO.itemCameraEventNotificationSettingDTOS.find(x => x.cameraEventStatusType == cameraEventStatusType);
        },
        notificationSettingData(data){
            if (data) {
                this.cameraEventNotificationSettingDTO.itemCameraEventNotificationSettingDTOS[data.cameraEventStatusType] = data;
            }
        },
        close() {
            this.$emit(CLOSE_POPUP);
        },
        cameraList() {
            this.$router.push(`/user/manage-camera`).catch(() => {
            })
        },
        showSuccess() {
            this.$store.commit('DELETE_CAMERA_STATUS', true);
            this.$vs.notify({
                color: 'success',
                text: this.$t('warningMenuCamera.notifySetting'),
                iconPack: 'feather',
                icon: 'icon-alert-circle',
            })
        },
        saveChanges() {
            if(this.isOragnizationSettings == true) {
                // check is enable notification via email but list email is empty
                if(this.cameraEventNotificationSettingDTO.turnOn && this.cameraEventNotificationSettingDTO.itemCameraEventNotificationSettingDTOS[0].cameraEventNotificationTypes.includes(1) &&
                    this.cameraEventNotificationSettingDTO.itemCameraEventNotificationSettingDTOS[0].emailList.length == 0) {
                        this.$vs.notify({
                            text: this.$t('notify.emailRequired'),
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        });
                        return;
                }
                if(this.cameraEventNotificationSettingDTO.turnOn && this.cameraEventNotificationSettingDTO.itemCameraEventNotificationSettingDTOS[0].cameraEventNotificationTypes.includes(2) &&
                    this.cameraEventNotificationSettingDTO.itemCameraEventNotificationSettingDTOS[0].phoneNumberList.length == 0) {
                        this.$vs.notify({
                            text: this.$t('notify.phoneRequired'),
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        });
                        return;
                }

                if(this.cameraEventNotificationSettingDTO.turnOn && !this.cameraEventNotificationSettingDTO.itemCameraEventNotificationSettingDTOS[0].always){
                    let check = false;
                    for (const [, value] of Object.entries(this.cameraEventNotificationSettingDTO.itemCameraEventNotificationSettingDTOS[0].timeNotification)) {
                        if(value.length > 0){
                            check = true;
                        }
                    }
                    if(!check){
                        this.$vs.notify({
                            text: this.$t('notify.timeRequired'),
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        });
                        return;
                    }
                }


                this.cameraEventNotificationSettingDTO.itemCameraEventNotificationSettingDTOS.forEach(setting => {
                    setting.emailList = setting.emailList.map(x => {return x.text});
                    setting.phoneNumberList = setting.phoneNumberList.map(x => {return x.text});
                    setting.timeNotification = JSON.stringify(setting.timeNotification);
                    delete setting.nameEventBusUse;
                });
                this.$emit(CLOSE_POPUP);
                this.isShowTab = false;
                this.payload = {
                    always: this.cameraEventNotificationSettingDTO.itemCameraEventNotificationSettingDTOS[0].always,
                    cameraEventNotificationTypes: this.cameraEventNotificationSettingDTO.itemCameraEventNotificationSettingDTOS[0].cameraEventNotificationTypes,
                    cameraEventStatusTypes: this.listNumberCameraEventStatusTypes,
                    emailList: this.cameraEventNotificationSettingDTO.itemCameraEventNotificationSettingDTOS[0].emailList,
                    periodNotify: this.cameraEventNotificationSettingDTO.itemCameraEventNotificationSettingDTOS[0].periodNotify,
                    phoneNumberList: this.cameraEventNotificationSettingDTO.itemCameraEventNotificationSettingDTOS[0].phoneNumberList,
                    timeNotification: this.cameraEventNotificationSettingDTO.itemCameraEventNotificationSettingDTOS[0].timeNotification,
                    turnOn: this.cameraEventNotificationSettingDTO.turnOn
                }
                this.$vs.loading();
                let url = this.isUpdate ? `/camera-event-notification/setting/update`
                    : `/camera-event-notification/setting/create`;
                this.$crm.post(url, this.payload).then(() => {
                    this.showSuccess();
                    this.$vs.loading.close();
                }).catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            } else {
                let checkValid = true;
                let messageError = '';
                let paramsDTOs = [];
                this.cameraEventNotificationSettingDTO.itemCameraEventNotificationSettingDTOS.forEach(t => paramsDTOs.push({...t}))
                paramsDTOs.forEach(setting => {

                    setting.emailList = setting.emailList.map(x => {return x.text});
                    setting.phoneNumberList = setting.phoneNumberList.map(x => {return x.text});
                    delete setting.nameEventBusUse;
                    let currentType = this.cameraEventStatusTypes.find(t => t.value == setting.cameraEventStatusType);
                    let currentTypeLabel = '';
                    if(currentType){
                        currentTypeLabel = currentType.label;
                    }
                    if(this.cameraEventNotificationSettingDTO.turnOn && setting.cameraEventNotificationTypes.includes(1) &&
                        setting.emailList.length == 0){
                        checkValid = false;
                        messageError = this.$t('notify.emailRequired') + ` "${currentTypeLabel}"`;
                    }
                    if(checkValid && this.cameraEventNotificationSettingDTO.turnOn && setting.cameraEventNotificationTypes.includes(2) &&
                        setting.phoneNumberList.length == 0){
                        checkValid = false;
                        messageError = this.$t('notify.phoneRequired') + ` "${currentTypeLabel}"`;
                    }

                    if(checkValid && this.cameraEventNotificationSettingDTO.turnOn && !setting.always && setting.cameraEventNotificationTypes.length > 0){
                        let check = false;
                        for (const [, value] of Object.entries(setting.timeNotification)) {
                            if(value.length > 0){
                                check = true;
                            }
                        }
                        if(!check){
                            checkValid = false;
                            messageError = this.$t('notify.timeRequired') + ` "${currentTypeLabel}"`;
                        }
                    }
                    setting.timeNotification = JSON.stringify(setting.timeNotification);
                });
                if(!checkValid){
                    return this.$vs.notify({
                        text: messageError,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                }

                this.cameraEventNotificationSettingDTO.itemCameraEventNotificationSettingDTOS = paramsDTOs;
                this.cameraEventNotificationSettingDTO.organizationBranchId = this.organizationBranchId;

                this.$emit(CLOSE_POPUP);
                this.isShowTab = false;

                this.$vs.loading();
                let url = this.isUpdate ? `/camera-event-notification/setting/camera/update?cameraId=${this.cameraId}`
                    : `/camera-event-notification/setting/camera/create?cameraId=${this.cameraId}`;
                this.$crm.post(url, this.cameraEventNotificationSettingDTO).then(() => {
                    this.showSuccess();
                    this.$vs.loading.close();
                }).catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            }
        },
        convertListToListTag(data) {
            return data.map(x => {
                return {
                    text: x,
                    tiClasses: ['ti-valid']
                }
            })
        },
        settingNotificationOrganization() {
            this.$crm.get(`/camera-event-notification/setting/organization`)
                .then((response) => {
                    this.$vs.loading.close();
                    if (response.data && response.data.itemCameraEventNotificationSettingDTOS) {
                        this.isUpdate = true;
                        let resultEventNotificationSetting;
                        resultEventNotificationSetting = response.data;
                        resultEventNotificationSetting.itemCameraEventNotificationSettingDTOS = resultEventNotificationSetting.itemCameraEventNotificationSettingDTOS.sort((a,b) => a.cameraEventStatusType > b.cameraEventStatusType ? 1 : -1)
                        resultEventNotificationSetting.itemCameraEventNotificationSettingDTOS.forEach((setting) => {
                            setting.timeNotification = JSON.parse(setting.timeNotification);
                            setting.emailList = this.convertListToListTag(setting.emailList);
                            setting.phoneNumberList = this.convertListToListTag(setting.phoneNumberList);
                            setting.nameEventBusUse = EVENT_NAME + setting.cameraEventStatusType;
                        });
                        delete resultEventNotificationSetting.organizationSetting;
                        resultEventNotificationSetting.itemCameraEventNotificationSettingDTOS.map(xi => {if(xi.periodNotify == 0 || xi.periodNotify == null || xi.periodNotify == undefined){xi.periodNotify = 3}}
                        )
                        this.cameraEventNotificationSettingDTO = resultEventNotificationSetting;
                    }
                })
                .catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
        },
        settingNotification() {
            this.$crm.post(`/camera/find-by-id/${this.cameraId}`)
                .then((response) => {
                    this.$vs.loading.close();
                    if (response.data && response.data.cameraEventNotificationSettingResponseDTO) {
                        this.isUpdate = true;
                        let resultEventNotification;
                        resultEventNotification = response.data.cameraEventNotificationSettingResponseDTO;
                        resultEventNotification.itemCameraEventNotificationSettingDTOS = resultEventNotification.itemCameraEventNotificationSettingDTOS.sort((a,b) => a.cameraEventStatusType > b.cameraEventStatusType ? 1 : -1)
                        resultEventNotification.itemCameraEventNotificationSettingDTOS.forEach((setting) => {
                            setting.timeNotification = JSON.parse(setting.timeNotification);
                            setting.emailList = this.convertListToListTag(setting.emailList);
                            setting.phoneNumberList = this.convertListToListTag(setting.phoneNumberList);
                            setting.nameEventBusUse = EVENT_NAME + setting.cameraEventStatusType;
                        });
                        delete resultEventNotification.organizationSetting;
                        resultEventNotification.itemCameraEventNotificationSettingDTOS.map(xi => {if(xi.periodNotify == 0 || xi.periodNotify == null || xi.periodNotify == undefined){xi.periodNotify = 3}}
                        )
                        this.cameraEventNotificationSettingDTO = resultEventNotification;
                    }
                })
                .catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
        },
        initTab(){
            this.cameraEventNotificationSettingDTO = {
                itemCameraEventNotificationSettingDTOS: [],
                organizationBranchId: null,
                turnOn: true
            };
            for (let i = 0; i < 6; i++) {
                let obj = {
                    cameraEventStatusType: i,
                    cameraEventNotificationTypes: [],
                    emailList: [],
                    phoneNumberList: this.convertListToListTag([this.userInfo.organization.phoneNumber]),
                    timeNotification: {
                        arr0: [],
                        arr1: [],
                        arr2: [],
                        arr3: [],
                        arr4: [],
                        arr5: [],
                        arr6: []
                    },
                    nameEventBusUse: EVENT_NAME + i,
                    always: false,
                    periodNotify: 3,
                };
                this.cameraEventNotificationSettingDTO.itemCameraEventNotificationSettingDTOS.push(obj);
            }
        },
    },
    created() {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo') || {});
        if (this.cameraId) this.settingNotification();
    },
    mounted() {
            this.$eventBus.$on('notify-camera-setting', ($event) => {
                if ($event.status == true) {
                    this.activeTab = 0;
                    this.isShowTab = true;
                    this.initTab();
                    this.settingNotificationOrganization();
                    this.isOragnizationSettings = true;
                }
            })
            this.$eventBus.$on('notify-camera', ($event) => {
                if ($event) {
                    this.activeTab = 0;
                    this.isOragnizationSettings = false;
                    this.isShowTab = true;
                    this.cameraId = $event.id ? $event.id : $event.cameraId;
                    this.organizationBranchId = $event.organizationBranchId;
                    this.cameraEventNotificationSettingDTO.organizationBranchId = $event.organizationBranchId;
                    this.initTab();
                    this.settingNotification();
                }
            })
    },
}
</script>

<style lang="scss">
</style>
