<template>
    <div id="page-branch-">
        <div class="vx-card p-6">
            <div class="flex items-end mb-4 ">
                <div class="flex-grow " style="display: inline-block">
                    <div class="vx-row">
                        <div class="vx-col sm:w-auto">
                            <label class="vs-input--label">{{ $t('menuEvent.time') }}</label>
                            <div class="flex-grow">
                                <date-range-picker
                                    ref="picker"
                                    :locale-data="{format: 'dd/mm/yyyy' }"
                                    :opens="'right'"
                                    @update="dateRangePicker"
                                    v-model="dateRange">
                                </date-range-picker>
                            </div>
                        </div>
                        <div class="vx-col vx-col sm:w-1/3 md:w-1/3 lg:w-1/3 w-full">
                            <label class="vs-input--label">Camera</label>
                            <div class="flex-grow">
                                <div class="vx-col w-full tree-event" @mousemove="onMouseMoveCard" @mouseleave="onMousedownCard()">
                                    <MySelectTree
                                        ref='treeCamera'
                                        :data='treeData'
                                        v-model='treeCameraValue'
                                        :pleasechoosetext='pleaseChooseTextModel'
                                        :searchtext="searchtext"
                                        :multiple="true"
                                        :tpl='tpl'
                                        :halfcheck='false'
                                        :node-check="0"/>
                                </div>
                            </div>
                        </div>

                        <div class="vx-col vx-col sm:w-1/3 md:w-1/3 lg:w-1/3 w-full">
                            <label class="vs-input--label">{{ $t('menuEvent.event') }}</label>
                            <div class="flex-grow">
                                <div class="vx-col w-full">
                                    <v-select v-model="selectedEvent"
                                              :options="eventList"
                                              multiple>
                                              <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                                    </v-select>
                                </div>
                            </div>
                        </div>
                        <div class="vx-col vx-col md:w-auto">
                            <div class="flex-grow mt-6">
                                <vs-button @click="initScreen">{{ $t('menuEvent.filter') }}</vs-button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="vx-row mt-2" v-for="(day,dayIndex) in days" :key="dayIndex">
                <div class="vx-col w-full mt-2 text-center" v-if="day.rowDatas.length < 1">
                    <span v-if="dayIndex == 0">{{ $t('menuEvent.dataNotFound') }}</span>
                </div>
                <div class="vx-col w-full" v-else>
                    <div class="event-display-date mt-2">
                        <div class="component text-center w-full">
                            <span>{{ day.displayDate }}</span>
                        </div>
                    </div>
                    <div class="vx-row">
                        <div class="vx-col w-full text-right">
                            <vs-button class="cursor-pointer" @click="loadMoreCamera(day)">{{ $t('button.loadMore') }}</vs-button>
                        </div>
                    </div>
                    <div class="vx-row">
                        <div class="vx-col w-full lg:w-1/5 mb-base mt-2 flex-container" :key="index"
                             v-for="(content, index) in day.rowDatas">
                            <vx-card class="card-img">
                                <div class="" slot="no-body">
                                    <img @click="openModalDetailEvent(content)" v-if="content.imageUrl" :src="content.imageUrl"
                                         class="responsive card-img-top">
                                    <img v-if="!content.imageUrl" src="@/assets/images/pages/img_camera_empty.png"
                                         class="responsive card-img-top">
                                    <div class="text-block">
                                        <p>{{ content.dateTime }}</p>
                                    </div>
                                    <div class="icon-events" :style="{backgroundColor: (content.cameraStatusEventType == 'disconnect'
                                                                    || content.cameraStatusEventType == 'stop_recording') ? '#d32424' : '#069862'}">

                                        <img v-bind:src="getImageEvent(content)" height="25"
                                             width="25" class="icon-type color-type"/>
                                    </div>
                                </div>
                                <div class="flex-container vx-row">
                                    <div class="vx-col w-full sm:w-2/3 md:w-2/3 lg:w-2/3">
                                        <div class="mb-2" style="flex-grow: 18">
                                            {{ cameraStatusEventTypeModel(content) }}
                                        </div>
                                    </div>
                                    <div class="vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3"
                                         style="direction: rtl; padding-right: 0px;">
                                        <vs-dropdown vs-custom-content vs-trigger-click style="flex-grow: 1"
                                                     class="cursor-pointer">
                                            <feather-icon @click="popupActive=true" icon="MoreVerticalIcon"
                                                          svgClasses="w-6 h-6 ml-12"/>
                                            <vs-dropdown-menu class="vx-navbar-dropdown" id="dropdown-menu">
                                                <ul>
<!--                                                    <li @click="showPopupNotifyPopup(content)" class="button-popup"-->
<!--                                                        type="filled">-->
<!--                                                        <feather-icon icon="SettingsIcon"-->
<!--                                                                      svgClasses="w-4 h-4 mr-4 ml-4"/>-->
<!--                                                        {{ $t('menuEvent.setting') }}-->

<!--                                                    </li>-->
                                                    <li @click="openModalDetailCamera(content)" class="button-popup"
                                                        type="filled">
                                                        <feather-icon icon="AlertCircleIcon"
                                                                      svgClasses="w-4 h-4 mr-4 ml-4"/>
                                                        {{ $t('menuEvent.detail') }}
                                                    </li>
                                                </ul>

                                            </vs-dropdown-menu>
                                        </vs-dropdown>
                                    </div>
                                </div>

                                <div style="display: flex">
                                    <feather-icon class="text-grey color" icon="VideoIcon" svgClasses="w-4 h-4 mr-2"/>
                                    <p class="text-grey color" style="display: inline-block">
                                        {{ content.cameraName }}
                                    </p>
                                </div>
                            </vx-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <vs-popup classContent="popup-example" id="popup-detail" :title="$t('menuEvent.detail')"
                  :active.sync="popupActiveDetail">
            <h5 class="text-grey detailTitle">
                <feather-icon icon="ServerIcon" svgClasses="w-4 h-4 mr-2 mt-4 mb-6"/>
                {{ detailCamera.cameraName }} -
                {{ detailCamera.id }}
            </h5>
            <p class="text-grey detailTitle">
                <feather-icon icon="GitBranchIcon" svgClasses="w-4 h-4 mr-2 mt-4 mb-6"/>
                <span>{{ $t('menuEvent.branch') }}:</span>
                {{ getNameBranch(detailCamera) }}
            </p>
            <p class="text-grey detailTitle">
                <feather-icon icon="VideoIcon" svgClasses="w-4 h-4 mr-2 mt-4 mb-6"/>
                <span>{{ $t('menuEvent.statusCamera') }} :</span>
                {{ cameraStatusEventTypeModel(eventStatusType) }}
            </p>
            <p class="text-grey detailTitle">
                <feather-icon icon="TagIcon" svgClasses="w-4 h-4 mr-2 mt-4 mb-6"/>
                <span>{{ $t('menuEvent.brandCamera') }} :</span>
                {{ detailCamera.manufacturer || this.$t('menuEvent.unexplainable') }}
            </p>
            <p class="text-grey detailTitle">
                <feather-icon icon="LinkIcon" svgClasses="w-4 h-4 mr-2 mt-4 mb-6"/>
                <span>Link camera :</span>
                {{ detailCamera.streamUrl || this.$t('menuEvent.unexplainable') }}
            </p>
            <hr class="mb-10">
            <vs-button class="mr-6 btn-popup1" @click="closePopup">
                {{ $t('button.close') }}
            </vs-button>
        </vs-popup>

        <vs-popup classContent="popup-filter" id="popup-filter" :title="$t('menuCamera.filter')"
                  :active.sync="popupFilterActive">
            <div class="filter-tab-content">
                <div class="vx-row">
                    <div class="vx-col">
                        <h3>{{ $t('menuCamera.time') }}</h3>
                    </div>
                </div>
                <div class="vx-row mt-2">
                    <div class="vx-col">
                        <date-range-picker
                            ref="picker"
                            :locale-data="{format: 'dd/mm/yyyy' }"
                            :opens="'right'"
                            @update="dateRangePicker"
                            v-model="dateRange">
                        </date-range-picker>
                    </div>
                </div>
            </div>
            <hr class="mb-10">
            <vs-button class="mr-6 btn-popup1" @click="closePopupFilter">
                {{ $t('button.close') }}
            </vs-button>
        </vs-popup>

        <NotificationCamera :openPopup="showPopupNotify" :closePopup="closePopup" :camera-event-status-types="cameraEventStatusTypes" :list-number-camera-event-status-types="listNumberCameraEventStatusTypes"
                            @closePopupNotification="closePopupNotification"></NotificationCamera>

        <!-- Modal detail event -->
        <vs-popup :title="$t('menuEvent.detail')" :active.sync="isShowDetailModal">
          <div class="filter-tab-content">
            <img
              style="width: 100%; height: 400px"
              :src="currentEventDetail.imageUrl"
            />

            <div v-if="currentEventDetail.cameraStatusEventType == 'face_detection' || currentEventDetail.cameraStatusEventType == 'mask_detection' ">
              <div class="loader center-name" v-if="isLoadingCurrentUser" />
              <div class="center-name"  v-if="!isLoadingCurrentUser">
                <span class="text-user-name" v-if="currentUserName" >{{currentUserName}}</span>
                <span class="text-user-id" v-if="currentUserId">{{currentUserId}}</span>
              </div>
            </div>

            <div class="flex-container row-mask mb-2 mt-5"
                v-if="currentEventDetail.cameraStatusEventType == 'face_detection' || currentEventDetail.cameraStatusEventType == 'mask_detection'">
                <div class="row-ratio-detail">
                    <span class="mask-title">{{$t('menuEvent.emoji')}}</span>
                    <img :src="handleDataEmoji(currentEventDetail, 1)" class="img-emoji-detail" alt="happy" />
                    <span class="text-ratio-detail">{{handleDataEmoji(currentEventDetail, 0)}}</span>
                </div>
                <div class="row-ratio-detail">
                    <span class="mask-title">{{handleDataAiMask(currentEventDetail, 0)}}</span>
                    <img :src="handleDataAiMask(currentEventDetail, 1)" class="img-emoji-detail" alt="happy" />
                    <span class="text-ratio-detail">{{handleDataAiMask(currentEventDetail, 2)}}</span>
                </div>
                <div class="row-ratio-detail">
                    <span class="mask-title">{{handleDataLiveness(currentEventDetail, 0)}}</span>
                    <img :src="handleDataLiveness(currentEventDetail, 1)" class="img-emoji-detail" alt="happy" />
                    <span class="text-ratio-detail">{{handleDataLiveness(currentEventDetail, 2)}}</span>
                </div>
            </div>
          </div>
        </vs-popup>

    </div>
</template>

<script>
import {AgGridVue} from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import Pagination from "../../ui-elements/Pagination.vue"
import CellRendererActions from "./cell-renderer/CellRendererActions.vue"
import PopupInnerPopup from './PopupInnerPopup.vue'
import authConfig from '@/../auth_config.json';
import moment from "moment";
import DateRangePicker from '../../../components/vue2-daterange-picker/components/DateRangePicker.vue'
import vSelect from 'vue-select'
import NotificationCamera from '../camera/my-cameras/Notification';

import {VTree, VSelectTree} from 'vue-tree-halower'
import "@/assets/scss/vuexy/extraComponents/tree.scss";
import imgErr from "../../../assets/images/pages/cam-error.png";
import imgWaiting from "../../../assets/images/pages/cam-waiting.png";
import imgSuccess from "../../../assets/images/pages/cam-succes.png";
import imgLiveNess1 from "../../../assets/images/pages/ic_liveness_1.png";
import imgLiveNess0 from "../../../assets/images/pages/ic_liveness_0.png";
import imgNoMask from "../../../assets/images/pages/ic_no_mask.png";
import imgMask from "../../../assets/images/pages/ic_mask.png";
import imgHappy from "../../../assets/images/pages/ic_emoji_happy.png";
import imgNeutral from "../../../assets/images/pages/ic_emoji_neutral.png";
import imgAngry from "../../../assets/images/pages/ic_emoji_angry.png";
import imgSad from "../../../assets/images/pages/ic_emoji_sad.png";
import imgSuprise from "../../../assets/images/pages/ic_emoji_suprise.png";
import MySelectTree from './../../../components/my-select-tree/MySelectTree.vue'


import imgEmpty from "../../../assets/images/pages/camera_empty_height_480.png";
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
    components: {
        AgGridVue,
        PopupInnerPopup,
        // Cell Renderer
        CellRendererActions,
        Pagination,
        DateRangePicker,
        vSelect,
        // VJstree,
        NotificationCamera,
        VSelectTree,
        VuePerfectScrollbar,
        VTree,
        MySelectTree
    },
    data() {
        return {
            //page
            totalPages: 0,
            cameraEventStatusTypes: [],
            listNumberCameraEventStatusTypes: [],
            numberOfDisplayPosts: 0,
            getAllType: [],
            settings: {
                maxScrollbarLength: 60,
                wheelSpeed: .30
            },
            imgErr: null,
            imgWaiting: null,
            imgSuccess: null,
            imgEmpty: null,
            treeCameraValue: [],
            cameraSelectedValue: [],
            treeData: [{
                isOrganization: true,
                title: null,
                expanded: true,
                children: []
            }],
            pleasechoosetext: this.$t('menuEvent.selectCamera'),
            showPopupNotify: false,
            content: [],
            eventStatusType: {},
            activePromptAddEvent: false,
            imageUrl: null,
            createdAt: '',
            insertedAt: '',
            cameraName: '',
            listEventsCamera: [],
            btnDetail: false,
            popupActive: false,
            popupActiveDetail: false,
            maxPageNumbers: 7,
            paginationPageSize: 20,
            currentPage: 0,
            cameraStatusEventType: [],
            organizationBranchName: '',
            listBranch: [],
            streamUrl: '',
            imgLiveNess1: null,
            imgLiveNess0: null,
            imgNoMask: null,
            imgHappy: null,
            imgNeutral: null,
            imgAngry: null,
            imgSad: null,
            imgSuprise: null,
            imgMask: null,
            isShowDetailModal: false,
            isLoadingCurrentUser: false,
            currentUserName: '',
            currentUserId: '',

            // Cell Renderer Components
            components: {
                CellRendererActions,
            },
            detailCamera: {},
            indexType: 0,
            days: [],
            popupFilterActive: false,
            tabIndex: 3,
            cameras: [],
            dateRange: {
                endDate: new Date(),
                startDate: (() => {
                    let date = new Date();
                    date.setDate(date.getDate() - 6);
                    date.setHours(0, 0, 0);
                    return date
                })()
            },
            selectedEvent: [],
            multipleSelect: true,
            eventList: [],
            showSelectCamera: false,
            selectedCameraIds: [],
            currentEventDetail: {},
        }
    },
    watch: {
        '$store.state.windowWidth'(val) {
            if (val <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }
        },
    },
    computed: {
        pleaseChooseTextModel() {
            return this.$t('menuEvent.selectCamera');
        },
        searchtext() {
            return this.$t('menuEvent.searchCam');
        },

        selectedEvent: {
            get() {
                return {
                    label: this.eventString(this.multipleSelect),
                    value: this.multipleSelect
                }
            },
            set(obj) {
                this.multipleSelect = obj.type
            }
        }

    },
    methods: {
        loadMoreCamera: function(item){
            this.$vs.loading();
            if (item.numberOfPage >= item.totalPages - 1) {
                setTimeout(() => {
                    this.$vs.loading.close();
                }, 100);
                return this.$vs.notify({
                    text: this.$t('menuEvent.textNotifyLoadMore') + item.displayDate,
                    icon: 'icon-alert-circle',
                    iconPack: 'feather',
                    color: 'success'
                })
            }
            item.numberOfPage += 1;
            this.loadDataEvent(item);
            setTimeout(() => {
                this.$vs.loading.close();
            }, 500);
        },
        eventString(id) {
            let event = this.eventList.find(x => x.type === id);
            return (event && event.label) || this.$t('menuEvent.undefined');
        },
        getEventType() {
            return new Promise(resolve => {
                this.$crm.get(`/camera-event-type/find-all`).then((response) => {
                    let result = response.data.filter(el => el.value == 0 || el.value == 1 || el.value == 4 || el.value == 5);
                    this.eventList = result.map(item => {
                        return {
                            icon: item.icon,
                            type: item.type,
                            label: item.label,
                        }
                    });
                    this.selectedEvent = result.map(item => {
                        return {
                            icon: item.icon,
                            type: item.type,
                            label: item.label,
                        }
                    });
                    this.cameraEventStatusTypes = result
                    this.listNumberCameraEventStatusTypes = result.map(x => {
                        return x.value
                    })
                }).catch((err) => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: this.$t('message.error'),
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                    resolve(false);
                });
            })
        },
        nodeSelected(node) {
        },
        onMousedownCard() {
            document.getElementsByClassName("tree-box")[0].style.display = "none";
        },
        onMouseMoveCard() {
            document.getElementsByClassName("tree-box")[0].style.display = "block";
        },
        showPopupNotifyPopup(content) {
            this.$eventBus.$emit('notify-camera', content);
            this.showPopupNotify = true;
        },
        closePopupNotification: function () {
            this.closePopupAll();
        },
        closePopupAll() {
            this.showPopupNotify = false;
            this.popupActiveDetail = false;
        },
        closePopup() {
            this.closePopupAll();
        },
        closePopupFilter() {
            this.popupFilterActive = false;
        },
        dateRangePicker() {
            this.dateRange.startDate = new Date(this.dateRange.startDate.setHours(0, 0, 0));
            this.dateRange.endDate = new Date(this.dateRange.endDate.setHours(23, 59, 59));
        },
        getCameraSelected() {
            let cameras = this.$refs.treeCamera.getTreeRef().getCheckedNodes().filter(x => x.isCamera);
            return cameras.map(x => x.value.id);
        },
        handleDataAiMask(content, type){
          let dataAi = JSON.parse(content.dataAi);
          if(type == 1){
            return dataAi.maskPredict.label == "no_mask" ? this.imgNoMask : this.imgMask;
          }else if(type == 0) {
            return dataAi.maskPredict.label == "no_mask" ? this.$t('menuEvent.noMask') : this.$t('menuEvent.mask');
          }else{
            return `${Number.parseInt(Number(dataAi.maskPredict.score) * 100)}%`;
          }
        },
        handleDataLiveness(content, type){
          let dataAi = JSON.parse(content.dataAi);
          if(type == 1){
            return dataAi.spoofingPredict.label == "0" ? this.imgLiveNess0 : this.imgLiveNess1;
          }else if(type == 0) {
            return dataAi.spoofingPredict.label == "0" ? this.$t('menuEvent.fake') : this.$t('menuEvent.auth');
          }else {
            return `${Number.parseInt(Number(dataAi.spoofingPredict.score) * 100)}%`;
          }
        },
        handleDataEmoji(content, type){
          let dataAi = JSON.parse(content.dataAi);
            let emotionPredict = this.$t(`menuEvent.${dataAi.emotionPredict.label}`);
            let emotionEcon = this.imgHappy;
            switch(dataAi.emotionPredict.label){
                case "angry":
                    emotionEcon = this.imgAngry;
                    break;
                case "happy":
                    emotionEcon = this.imgHappy;
                case "neutral":
                    emotionEcon = this.imgNeutral;
                    break;
                case "sad":
                    emotionEcon = this.imgSad;
                    break;
                case "suprise":
                    emotionEcon = this.imgSuprise;
                    break;
            }
            if(type == 1){
                return emotionEcon;
            }else {
                return emotionPredict;
            }
        },
        initScreen() {
            this.$vs.loading();
            let endDate = moment(this.dateRange.endDate);
            let startDate = moment(this.dateRange.startDate);
            let listDays = [];
            while (startDate <= endDate) {
                listDays.push({
                    date: moment(startDate),
                    rowDatas: [],
                    displayDate: moment(startDate).format("DD/MM/YYYY")
                })
                startDate.add(1, "days")
            }
            this.$crm.post('/organization-branch/all').then((response) => {
                this.listBranch = response.data;//lấy dữ liệu data trả về gắn bằng listBranch
                listDays.sort((a, b) => b.date - a.date);
                // listDays.map(item => {
                //     this.loadDataEvent(item);
                //     return item;
                // });
                this.days = listDays.map(el => {
                    el.numberOfPage = this.numberOfDisplayPosts;
                    this.loadDataEvent(el);
                    return el;
                });
                this.$vs.loading.close();
                // Promise.all(listDays.slice(0, this.numberOfDisplayPosts).map(item => this.loadDataEvent(item))).then(() => {
                //     listDays.reverse()
                //     console.log(listDays.slice(0, 3));
                //     this.days = listDays.slice(0,this.numberOfDisplayPosts);
                // }).catch((err) => {
                // }).finally(() => {
                //     this.$vs.loading.close()
                // })
                // this.days.forEach(day => {
                //     this.loadDataEvent(day);
                // })
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        loadDataEvent(day) {
            return new Promise((resolve, reject) => {
                let startDateQuery = day.date.clone().set({hours: 0, minute: 0, second: 0})
                let endDateQuery = day.date.clone().set({hours: 23, minute: 59, second: 59})
                this.$processor.post(`camera-event/find-all` + `?size=10&page=${day.numberOfPage}`, {
                    cameraEventStatusTypes: this.selectedEvent.map(item => item.type),
                    startTime: startDateQuery,
                    endTime: endDateQuery,
                    cameraIds: this.getCameraSelected()
                }).then((response) => {
                    let rowDatas = response.data.content.map(x => {
                        x.dateTime = x.createdAt ? moment(x.createdAt).format("DD/MM/YYYY HH:mm:ss") : null;
                        if (x.cameraStatusEventType == 'face_detection') {
                            x.imageUrl = authConfig.processorDomain + "storage/" + x.imageUrl
                        }
                        if (x.cameraStatusEventType == 'mask_detection') {
                            x.imageUrl = authConfig.processorDomain + "storage/" + x.imageUrl
                        }
                        return {
                            imageUrl: x.imageUrl,
                            dateTime: x.dateTime,
                            cameraName: x.cameraName,
                            cameraId: x.cameraId,
                            cameraStatusEventType: x.cameraStatusEventType,
                            organizationBranchId: x.organizationBranchId,
                            organizationId: x.organizationId,
                            dataAi: x.dataAi,
                        };
                    })
                    this.totalPages = response.totalPages;
                    day.rowDatas = rowDatas.map(el => {
                        el.totalPages = this.totalPages;
                        return el;
                    });
                    day.totalPages = response.data.totalPages;
                }).catch((err) => {
                }).finally((err) => {
                    resolve();
                });
                ;
            })
        },
        cameraStatusEventTypeModel(content) {
          let currentType = this.eventList.find(t => t.type == content.cameraStatusEventType)
          if(currentType){
            return currentType.label;
          }
        },
        getNameBranch(camera) {
            let currentBranch = this.listBranch.find(t => t.id == camera.organizationBranchId)
            if (currentBranch) {
                return currentBranch.organizationBranchName
            }
            return this.$t('menuEvent.unexplainable')
        },
        getDetailCamera(camera) {
            this.$crm.post(`/camera/find-by-id/${camera.cameraId}`, {}).then((response) => {
                this.detailCamera = response.data
            }).catch((err) => {
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        openModalDetailCamera(camera) {
            this.eventStatusType = camera
            this.getDetailCamera(camera)
            this.popupActiveDetail = true
        },
        loadCameras() {
            this.$crm.get(`/camera-group/find-camera-by-org`).then((response) => {
                let treeData = response.data.map(branchItem => {
                    branchItem.text = branchItem.organizationBranchName;
                    branchItem.children = branchItem.cameraGroupResponseDTO.map(groupItem => {
                        groupItem.text = groupItem.cameraGroupName;
                        groupItem.children = groupItem.cameras.map(cameraItem => {
                            cameraItem.text = cameraItem.cameraName;
                            cameraItem.selected = true;
                            cameraItem.opened = false;
                            return cameraItem;
                        })
                        groupItem.selected = true;
                        groupItem.opened = false;
                        groupItem.type = "group";
                        return groupItem;
                    })
                    branchItem.selected = true;
                    branchItem.opened = false;
                    branchItem.type = "branch";
                    let otherGroupCamera = branchItem.camerasNotInGroup.map(otherCamera => {
                        otherCamera.text = otherCamera.cameraName;
                        otherCamera.selected = true;
                        otherCamera.opened = false;
                        return otherCamera;
                    })
                    branchItem.children.push({
                        text: this.$t('menuEvent.other'),
                        children: otherGroupCamera,
                        selected: true,
                        opened: false,
                        type: "group"
                    })
                    return branchItem;
                });
                let selectedCameraIds = [];
                treeData.forEach(branchItem => {
                    branchItem.children.forEach(groupItem => {
                        groupItem.children.forEach(cameraItem => {
                            selectedCameraIds.push(cameraItem.id)
                        })
                    })
                })
                this.selectedCameraIds = selectedCameraIds;
                this.cameras.push(...treeData);
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        itemClick(node) {
            let cameraInSelectedItem = [];
            if (node.model.type == "branch") {
                node.model.children.forEach(groupItem => {
                    groupItem.children.forEach(cameraItem => {
                        cameraInSelectedItem.push(cameraItem.id)
                    })
                })
            }
            if (node.model.type == "group") {
                node.model.children.forEach(cameraItem => {
                    cameraInSelectedItem.push(cameraItem.id)
                })
            } else {
                cameraInSelectedItem.push(node.model.id)
            }
            if (node.model.selected) {
                this.selectedCameraIds.push(...cameraInSelectedItem);
            } else {
                this.selectedCameraIds = this.selectedCameraIds.filter(cameraId => !cameraInSelectedItem.includes(cameraId));
            }
        },
        chooseCamera() {
            this.showSelectCamera = true;
        },
        filterCamera() {
            this.showSelectCamera = false;
        },
        getCamera() {
            return new Promise(resolve => {
                this.$vs.loading();
                this.cameras = [];
                this.camerasBinding = [];
                this.treeData = [{
                    isOrganization: true,
                    title: null,
                    expanded: true,
                    children: [],
                    checked: true
                }],
                    this.userInfo = JSON.parse(localStorage.getItem('userInfo') || {});
                this.treeData[0].title = this.userInfo.username;
                this.$crm.get(`/camera-group/find-camera-by-org?type=2`).then((response) => {
                    if (response.data && response.data.length > 0) {
                        response.data.forEach(data => {
                            let childOrganization = {
                                title: data.organizationBranchName,
                                expanded: true,
                                value: data,
                                children: [],
                                checked: true
                            }
                            let childOrganizationBranchInGroup = {};
                            if (data.cameraGroupResponseDTO && data.cameraGroupResponseDTO.length > 0) {
                                data.cameraGroupResponseDTO.forEach(camInGroup => {
                                    let childGroup = camInGroup.cameras.map(cam => {
                                        if (!cam.evgCamera) return;
                                        this.treeCameraValue.push(cam.cameraName);
                                        return {
                                            title: cam.cameraName,
                                            value: cam,
                                            isCamera: true,
                                            checked: true
                                        }
                                    })
                                    childOrganizationBranchInGroup = {
                                        title: camInGroup.cameraGroupName,
                                        expanded: true,
                                        value: camInGroup,
                                        children: childGroup,
                                        checked: false
                                    }
                                    childOrganization.children.push(childOrganizationBranchInGroup);
                                });
                            }
                            if (data.camerasNotInGroup && data.camerasNotInGroup.length > 0) {
                                let childOrganizationBranchNotInGroup = data.camerasNotInGroup.map(camNotInGroup => {
                                    this.treeCameraValue.push(camNotInGroup.cameraName);
                                    return {
                                        title: camNotInGroup.cameraName,
                                        value: camNotInGroup,
                                        isCamera: true,
                                        checked: true
                                    }
                                })
                                childOrganization.children.push(...childOrganizationBranchNotInGroup);
                            }
                            if(data.cameraGroupShareResponseDTO && data.cameraGroupShareResponseDTO.length > 0){
                                data.cameraGroupShareResponseDTO.forEach(camShare => {
                                    let childGroup = camShare.cameras.map(cam => {
                                        if (!cam.evgCamera) return;
                                        cam.isCameraShare = true;
                                        return {
                                            title: cam.cameraName,
                                            value: cam,
                                            isCamera: true
                                        }
                                    })
                                    childOrganizationBranchInGroup = {
                                        title: camShare.cameraGroupName,
                                        expanded: true,
                                        value: camShare,
                                        isGroupCamera: true,
                                        isGroupShare: true,
                                        children: childGroup
                                    }
                                    childOrganization.children.push(childOrganizationBranchInGroup);
                                });
                            }

                            this.treeData[0].children.push(childOrganization);
                        });
                    }
                    this.$vs.loading.close();
                    resolve(true);
                }).catch((err) => {
                    resolve(true);
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            })
        },
        getChild(node) {
            if (node.children) {
                let children = node.children;
                for (const child of children) {
                    if (child.isCamera) {
                        this.cameras.push(child.value);
                    }
                    if (child.children) this.getChild(child);
                }
            }
        },
        tpl(...args) {
            // <img v-if="false" src={this.imgErr}/>
            let {0: nodeValue, 2: parent, 3: index} = args
            let titleClass = nodeValue.selected ? 'node-title node-selected' : 'node-title'
            if (nodeValue.searched) titleClass += ' node-searched'
            let img = null;
            if (nodeValue.isCamera) {
                let streamingStatus = nodeValue.value.evgCamera.streamingStatus;
                switch (streamingStatus) {
                    case 0:
                        img = <img style="width:20px; height:15px; margin-left: 5px" src={this.imgErr}/>;
                        break;
                    case 1:
                        img = <img style="width:20px; height:15px; margin-left: 5px" src={this.imgSuccess}/>;
                        break;
                    case 8:
                        img = <img style="width:20px; height:15px; margin-left: 5px" src={this.imgWaiting}/>;
                        break;
                }
            }
            return <span style="display: inline-flex; align-items: center;">
                {img}
                <span class={titleClass} domPropsInnerHTML={nodeValue.title} onClick={() => {
                    this.nodeSelected(nodeValue)
                }}></span>
      </span>
        },
        openModalDetailEvent: function (content) {
            this.currentEventDetail = content;
            this.isShowDetailModal = true;
            let dataAi = JSON.parse(content.dataAi);
            if(dataAi.employeeId != null ){
                this.isLoadingCurrentUser = true;
                if(dataAi.faceType == "employee"){
                    this.$crm.post(`/employee/find-by-id/${dataAi.employeeId}`, {})
                        .then(res => {
                            this.currentUserName = res.data.fullName;
                            this.currentUserId = res.data.employeeCode;
                            this.isLoadingCurrentUser = false;
                        })
                        .catch(err => {
                            this.isLoadingCurrentUser = false;
                        })
                }else {
                    this.$crm.get(`/customer/find-by-id/${dataAi.employeeId}`)
                        .then(res => {
                            this.currentUserName = res.data.fullName;
                            this.currentUserId = res.data.customerCode;
                            this.isLoadingCurrentUser = false;
                        })
                        .catch(err => {
                            this.isLoadingCurrentUser = false;
                        })
                }
            }else{
                this.currentUserName = '';
                this.currentUserId = '';
            }
        },
        closeModalDetailEvent: function () {
          this.isShowDetailModal = false;
        },
        getImageEvent(content){
          let currentType = this.eventList.find(t => t.type == content.cameraStatusEventType)
          if(currentType){
            return `data:image/png;base64,${currentType.icon}`
          }
        }

    },
    mounted() {
    },
    async created() {
        this.getEventType();
        this.imgSuccess = imgSuccess;
        this.imgErr = imgErr;
        this.imgWaiting = imgWaiting;
        this.imgEmpty = imgEmpty;
        this.imgLiveNess1 = imgLiveNess1;
        this.imgLiveNess0 = imgLiveNess0;
        this.imgNoMask = imgNoMask;
        this.imgHappy = imgHappy;
        this.imgNeutral = imgNeutral;
        this.imgAngry = imgAngry;
        this.imgSad = imgSad;
        this.imgSuprise = imgSuprise;
        this.imgMask = imgMask;

        await this.getCamera();
        this.initScreen();
    }
}
</script>
<style scoped>
#btn-popup {
    float: right;
}

.btn-popup1 {
    float: right !important;
}

.icon-type {
  width: 16px;
  height: 16px;
}

.card-img {
    /* width: 360px; */
}

.card-img-top {
    height: 189px;
}

.feather-bell {
    color: #2c2c2c !important;
}

.button-popup {
    color: #2c2c2c !important;
    background: white !important;
}

.button-popup:hover {
    color: #1aaa55 !important;
}

.text-block {
    left: 30px;
    direction: rtl;
    position: absolute;
    top: 1px;
    color: white;
    background-color: rgba(39, 44, 50, 0.7);


    right: 0;
    border-radius: 0px 5px 0px 0px;
    padding: 6px;
}

.icon-events {
    position: absolute;
    top: 0;
    background-color: #1aaa55;
    border-width: 1px;
    width: 34px;
    height: 34px;
    border-top-left-radius: 0.5rem;
}

.container {
    position: relative;
    font-family: Arial;
}

.btn-drop {
    display: inline-block;

}

.detailTitle {
    color: black !important;
}

.filter-btn {
    display: inline-block;
    text-transform: uppercase;
    font-weight: bold;
    color: black;
}

.flex-container {
    display: flex;
    align-items: stretch;
    /*background-color: #f1f1f1;*/
}

span {
    font-weight: 600;
}

.color-type {
    width: 18px;
    height: 100%;
    margin: auto;
    object-fit: contain;
}

#dropdown-menu {
    width: 250px;
}

vs-popup {
    background-color: white;
}

li {
    height: 25px;
    font-size: 16px;
}

#page-branch-list {
}

.event-display-date {
    flex-flow: row nowrap;
    font-size: 18px;
    justify-content: center;
}

.filter-tab-content {
    width: 40vw;
    height: 60vh;
    min-width: 700px;
    padding-top: 1rem
}
</style>
<style lang="scss">
.tree-event .tree-box {
    margin-top:3px;
    box-shadow: 0 0 5px rgb(0 0 0 / 20%);
}
.tree-box {
    background: #fff;
    position: relative;
    z-index: 9;
    max-height: 500px;
    overflow: auto;
    margin-top: 0px;

    .search-input {
        margin-top: 10px;
        width: 98%;
        display: block;
    }
}

.rmNode {
    background-color: rgba(var(--vs-danger), 0.15) !important;
    color: rgba(var(--vs-danger), 1) !important;
    line-height: 13px !important;
}

.tree-container {
    width: 100%;
}

.scroll-area--nofications-dropdown, .scroll-area--cart-items-dropdowm {
    max-height: 750px;
}

.tree-wholerow {
    background: #e1e1e1
}

.img-emoji{
    width: 1.4rem;
    height: 1.4rem;
    margin: auto;
}

.row-mask{
    justify-content: space-between;
    display: flex;
}

.row-ratio{
    display: block;
    align-items: center;
}

.text-ratio{
    font-size: 12px;
    padding-top: 0.4rem;
    font-weight: 400 !important;
}

.row-mask-detail{
    justify-content: space-around;
    padding-top: 3rem;
}

.img-emoji-detail{
    width: 2rem;
    height: 2rem;
}

.row-ratio-detail{
    display: flex;
    align-items: center;
    flex: 1;
    flex-direction: column;
}

.text-ratio-detail{
    font-size: 18px;
    padding-top: 1rem;
    font-weight: 400 !important;
}

.mask-title{
    padding-bottom: 1rem;
    font-size: 14px;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #069862;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.text-user-name {
  font-size: 24px;
}

.text-user-id {
  font-size: 14px;
  padding-top: 6px;
}

.center-name{
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
</style>
