 <template>
    <div>
        <div class="vx-row">
            <div class="vx-col sm:w-full md:w-full">
                <h5 class="color mt-5">{{ $t('notify.notifyForm') }}</h5>
            </div>
            <div class="vx-col md:w-1/2 mt-2 flex">
                <vs-checkbox :disabled="disabledAllNorificationSetting" class="mt-2" v-model="firebaseCheckBoxModel"></vs-checkbox>
                <span class="mt-2 flex">{{ $t('notify.notifyApp') }}</span>
            </div>
        </div>
        <div class="vx-row notification-setting">
            <div class="vx-col sm:w-full md:w-1/2">
                <div class="w-full flex">
                    <vs-checkbox :disabled="disabledAllNorificationSetting" class="mt-2" v-model="emailCheckBoxModel"></vs-checkbox>
                    <span class="mt-2">{{ $t('notify.notifyEmail') }}</span>
                </div>
                <div class="vx-col md:w-full p-2" v-if="emailCheckBoxModel">
                    <vue-tags-input :placeholder="$t('notify.insertEmail')"
                                    v-model="email"
                                    :disabled="disabledAllNorificationSetting"
                                    :tags=notificationSetting.emailList
                                    @tags-changed="newTags => notificationSetting.emailList = newTags"
                                    @before-adding-tag="checkTag"
                                    allow-edit-tags
                                    v-validate="'email|min:3|max:50'"
                                    name="email"
                    />
                    <span class="text-danger text-sm">{{ errors.first("email") }}</span>
                </div>
            </div>

            <div class="vx-col sm:w-full md:w-1/2">
                <div class="w-full flex">
                    <vs-checkbox :disabled="disabledAllNorificationSetting" class="mt-2" v-model="smsCheckBoxModel"></vs-checkbox>
                    <span class="mt-2">{{ $t('notify.notifyPhone') }}</span>
                </div>
                <div class="vx-col md:w-full p-2" v-if="smsCheckBoxModel">
                    <vue-tags-input :placeholder="$t('notify.insertPhone')"
                                    v-model="phoneNumber"
                                    :disabled="disabledAllNorificationSetting"
                                    :tags=notificationSetting.phoneNumberList
                                    @tags-changed="newTags => notificationSetting.phoneNumberList = newTags"
                                    @before-adding-tag="checkTag"
                                    allow-edit-tags
                                    v-validate="'numeric|min:10|max:11'"
                                    name="phoneNumber"
                    />
                    <span class="text-danger text-sm">{{ errors.first("phoneNumber") }}</span>
                </div>
            </div>
        </div>
        <div class="vx-row">
            <div class="vx-col md:w-2/3">
                <h5 class="color mt-5">{{ $t('notify.timeNotify') }}</h5>
                <span class="ml-5" style="font-size: 12px;">{{ $t('notify.selectTime') }}</span>
            </div>
            <div class="vx-col md:w-1/3 float-right flex">
                <vs-checkbox :disabled="disabledAllNorificationSetting" v-model="notificationSetting.always">
                </vs-checkbox>
                <span class="mt-5 flex">{{ $t('notify.alwaysNotify') }}</span>
            </div>
        </div>
        <WeekComponentNew
            :is-button-disable="disabledAllNorificationSetting || notificationSetting.always"
            :selectTimes="notificationSetting.timeNotification"
            :nameEventBus="notificationSetting.nameEventBusUse" :disabled-all-notification-week="!disabledAllNotification"></WeekComponentNew>
        <div class="vx-row">
            <div class="vx-col sm:w-full md:w-full mb-2">
                <h5 class="color mt-5">{{ $t('notify.periodForm') }}</h5>
            </div>
            <div class="vx-col md:w-1/3 w-full mt-2" >
                <vs-input class="w-full" :label="$t('notify.period')" v-model="notificationSetting.periodNotify"
                          v-validate="'required|min:1'"
                          min="1"
                          :disabled="disabledAllNorificationSetting"
                          @input="handlerPeriodNotify"
                         name="period"
                type="number" />
                <span class="text-danger text-sm">{{ errors.first('period') }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import WeekComponentNew from "../week-time/WeekComponentNew";
import VueTagsInput from '@johmun/vue-tags-input';

//Camera Event Notification Types
const FIRE_BASE = 0;
const EMAIL = 1;
const SMS = 2;

export default {
    name: "NotificationSetting",
    data() {
        return {
            email: '',
            phoneNumber: '',
            settings: {
                maxScrollbarLength: 60,
                wheelSpeed: .30,
                suppressScrollX: true
            },
            disabledAllNorificationSetting: null
        }
    },
    computed: {
        firebaseCheckBoxModel: {
            get() {
                if (this.notificationSetting && this.notificationSetting.cameraEventNotificationTypes.includes(FIRE_BASE)) return true;
                return false;
            },
            set(value) {
                if (value) {
                    this.notificationSetting.cameraEventNotificationTypes.push(FIRE_BASE);
                } else {
                    this.notificationSetting.cameraEventNotificationTypes = this.notificationSetting.cameraEventNotificationTypes.filter(x => x != FIRE_BASE);
                }
            }
        },
        emailCheckBoxModel: {
            get() {
                if (this.notificationSetting && this.notificationSetting.cameraEventNotificationTypes.includes(EMAIL)) return true;
                return false;
            },
            set(value) {
                if (value) {
                    this.notificationSetting.cameraEventNotificationTypes.push(EMAIL);
                } else {
                    this.notificationSetting.cameraEventNotificationTypes = this.notificationSetting.cameraEventNotificationTypes.filter(x => x != EMAIL);
                }
            }
        },
        smsCheckBoxModel: {
            get() {
                if (this.notificationSetting && this.notificationSetting.cameraEventNotificationTypes.includes(SMS)) return true;
                return false;
            },
            set(value) {
                if (value) {
                    this.notificationSetting.cameraEventNotificationTypes.push(SMS);
                } else {
                    this.notificationSetting.cameraEventNotificationTypes = this.notificationSetting.cameraEventNotificationTypes.filter(x => x != SMS);
                }
            }
        }
    },
    components: {
        WeekComponentNew,
        VueTagsInput
    },
    props: {
        notificationSetting: {
            cameraEventStatusType: null,
            cameraEventNotificationTypes: [],
            emailList: [],
            phoneNumberList: [],
            timeNotification: {
                arr0: [],
                arr1: [],
                arr2: [],
                arr3: [],
                arr4: [],
                arr5: [],
                arr6: []
            },
            nameEventBusUse: 'camera-setting',
            always: true,
        },
        disabledAllNotification: null
    },
    watch: {
        notificationSetting: {
            handler: function (val) {
                this.$emit('notification-setting-data', val);
            },
            deep: true
        },
        disabledAllNotification: {
            handler: function (val) {
               this.disabledAllNorificationSetting = val;
            },
            deep: true
        },
    },
    methods: {
        async checkTag(obj) {
            if (!this.validateForm) return;
            const result = await this.$validator.validateAll();
            if (!result) return;
            obj.addTag();
        },
        handlerPeriodNotify(value){
            if(value < 1){
                this.notificationSetting.periodNotify = 1
            }
        },
        validateForm() {
            return !this.errors.any()
        },
    },
    created() {
        this.disabledAllNorificationSetting = this.disabledAllNotification;
    },
    mounted() {
        this.$eventBus.$on(this.notificationSetting.nameEventBusUse, (data) => {
            this.notificationSetting.timeNotification = data.data;
        });
    }
}
</script>

<style lang="scss">
.scroll-item {
    max-height: 100px;
}

.notification-setting {
    .ti-input {
        max-height: 80px;
        overflow: auto;
        border-radius: 5px;
    }

    .ti-tag {
        height: 25px;
        background-color: rgba(var(--vs-primary),1);
    }
}
</style>
