<template>
    <div class="p-6" id="week-timeline">

        <div class="vc-ui" id="vc-ui" v-show="isShowPopup">
            <div class="float-right">
                <feather-icon icon="XIcon" class="action-err icon-btn"
                              svgClasses="h-4 w-4 hover:text-danger cursor-pointer"
                              @click="confirmRejectTimeUse"/>
            </div>
            <div class="vx-row color" style="padding: 10px; align-items: center;">
                <vs-input min="0" max="23" class="width-59" type="number" data-vv-validate-on="blur"
                          v-model="timeUsePopup.hourStart"/>
                <span class="ml-2 mr-2 color"> : </span>
                <vs-input min="0" max="59" class="width-59" type="number"
                          data-vv-validate-on="blur" v-model="timeUsePopup.minuteStart"/>
                <span class="ml-2 mr-2 color"> - </span>
                <vs-input min="0" max="24" class="width-59" type="number"
                          data-vv-validate-on="blur" v-model="timeUsePopup.hourEnd"/>
                <span class="ml-2 mr-2 color"> : </span>
                <vs-input min="0" max="59" class="width-59" type="number"
                          data-vv-validate-on="blur" v-model="timeUsePopup.minuteEnd"/>
                <div class="mt-3" style="margin: auto;">
                    <span class="ml-2 mr-5 color cursor-pointer"> <b @click="saveTimeUse">{{$t('button.save')}}</b> </span>
                    <span class="ml-2 mr-2 color"> | </span>
                    <span class="ml-5 mr-2 color cursor-pointer"> <b @click="removeTimeUse">{{$t('button.delete')}}</b> </span>
                </div>
            </div>
        </div>
        <div class="mt-3 vx-row" v-for="(item, index) in selectTimes" :key="index">
            <div class="vx-col label-weak">
                <label>{{ index == 'arr0' ? $t('weekTime.monday') : index == 'arr1' ? $t('weekTime.tuesday') : index == 'arr2' ? $t('weekTime.wednesday') : index
                    == 'arr3' ? $t('weekTime.thursday') : index == 'arr4' ? $t('weekTime.friday') : index == 'arr5' ? $t('weekTime.saturday') : index == 'arr6'
                    ? $t('weekTime.sunday') : null}}</label>
            </div>
            <div class="bl-time-clock-v2 mb-5 vx-col" :class="zIndexValue" @mousedown="mouseover($event, index)">
                <div class="row-number d-flex">
                    <div class="colm">
                        <span>0</span>
                    </div>
                    <div class="colm">
                        <span class="hideNumber">1</span>
                    </div>
                    <div class="colm">
                        <span>2</span>
                    </div>
                    <div class="colm">
                        <span class="hideNumber">3</span>
                    </div>
                    <div class="colm">
                        <span>4</span>
                    </div>
                    <div class="colm">
                        <span class="hideNumber">5</span>
                    </div>
                    <div class="colm">
                        <span>6</span>
                    </div>
                    <div class="colm">
                        <span class="hideNumber">7</span>
                    </div>
                    <div class="colm">
                        <span>8</span>
                    </div>
                    <div class="colm">
                        <span class="hideNumber">9</span>
                    </div>
                    <div class="colm">
                        <span>10</span>
                    </div>
                    <div class="colm">
                        <span class="hideNumber">11</span>
                    </div>
                    <div class="colm">
                        <span>12</span>
                    </div>
                    <div class="colm">
                        <span class="hideNumber">13</span>
                    </div>
                    <div class="colm">
                        <span>14</span>
                    </div>
                    <div class="colm">
                        <span class="hideNumber">15</span>
                    </div>
                    <div class="colm">
                        <span>16</span>
                    </div>
                    <div class="colm">
                        <span class="hideNumber">17</span>
                    </div>
                    <div class="colm">
                        <span>18</span>
                    </div>
                    <div class="colm">
                        <span class="hideNumber">19</span>
                    </div>
                    <div class="colm">
                        <span>20</span>
                    </div>
                    <div class="colm">
                        <span class="hideNumber">21</span>
                    </div>
                    <div class="colm">
                        <span>22</span>
                    </div>
                    <div class="colm">
                        <span class="hideNumber">23</span>
                    </div>

                </div>
                <div class="row-progress" @mousemove="mousemove($event, index)" @mouseup="mouseup">
                    <div v-for="(value, i) in item" :key="i">
                        <div
                            :class="'bl-time-progress bl-time-progress-' + index + ' bl-time-progress-' + index + '-' + i"
                            :style="{ left: value.from / 2 + 'px', width: ((value.to - value.from) / 2) + 'px', opacity: isButtonDisable ? 0.5 : 1 }"></div>
                    </div>
                </div>
            </div>
            <vx-tooltip  class="mt-1" :text="$t('weekTime.copy')" position="top">
                <feather-icon v-if="!isButtonDisable" :style="{color: '#4c4c4c'}" icon="CopyIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
                            @click="copyTimeUse(item)"/>
                <feather-icon v-if="isButtonDisable" :style="{color: '#eee'}" icon="CopyIcon" svgClasses="h-5 w-5 mr-4"/>
            </vx-tooltip>
            <vx-tooltip class="mt-1" :text="$t('weekTime.paste')" position="top">
                <feather-icon v-if="!isButtonDisable" :style="{color: '#4c4c4c'}" icon="PenToolIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
                              @click="pasteTimeUse(index)"/>
                <feather-icon v-if="isButtonDisable" :style="{color: '#eee'}" icon="PenToolIcon" svgClasses="h-5 w-5 mr-4"/>
            </vx-tooltip>
        </div>
    </div>
</template>


<script>
    import vSelect from 'vue-select';

    export default {
        name: 'WeekComponentNew',
        data() {
            return {
                zIndexValue: 'zIndexValueZero',
                isShowPopup: false,
                timeUsePopup: {
                    hourStart: null,
                    minuteStart: null,
                    hourEnd: null,
                    minuteEnd: null
                },
                arrCopy: [],
                dateUse: '',
                timeSelect: {
                    fromHourString: '',
                    toHourString: '',
                },
                timeSpans: [],
                popupActiveUse: false,
                popupDayUse: null,
                arrDayPopUp: [],
                layerX: 0,
                layerXMouseMove: 0,
                insertValue: 0,
                isMouseDown: false,
                isMouseDownDone: 0,
                startPaintProgress: null,
                indexStartPaintProgress: null,
                progressFocus: {}
            }
        },
        watch: {
            'timeUsePopup.hourStart': function () {
                if (parseInt(this.timeUsePopup.hourStart) > 23) this.timeUsePopup.hourStart = 23;
            },
            'timeUsePopup.minuteStart': function () {
                if (parseInt(this.timeUsePopup.minuteStart) > 59) this.timeUsePopup.minuteStart = 59;
            },
            'timeUsePopup.hourEnd': function () {
                if (parseInt(this.timeUsePopup.hourEnd) > 24) {
                    this.timeUsePopup.hourEnd = 24;
                    this.timeUsePopup.minuteEnd = 0;
                }
                if (parseInt(this.timeUsePopup.hourEnd) == 24) {
                    this.timeUsePopup.minuteEnd = 0;
                }
            },
            'timeUsePopup.minuteEnd': function () {
                if (parseInt(this.timeUsePopup.hourEnd) == 24) {
                    this.timeUsePopup.minuteEnd = 0;
                } else if (parseInt(this.timeUsePopup.minuteEnd) > 59) {
                    this.timeUsePopup.minuteEnd = 59;

                }
            },
            disabledAllNotificationWeek: {
                handler: function (val) {
                    if(val == true) {
                            this.zIndexValue = 'zIndexValueZero'
                          }
                          if(val == false) {
                            this.zIndexValue = 'zIndexValue'
                          }
                },
                deep: true
            },
        },
        props: {
            nameEventBus: null,
            selectTimes: {
                arr0: [],
                arr1: [],
                arr2: [],
                arr3: [],
                arr4: [],
                arr5: [],
                arr6: []
            },
            disabledAllNotificationWeek: null,
            isButtonDisable: false,
        },
        components: {
            vSelect
        },
        computed: {
            validateForm() {
                return !this.errors.any()
            },
        },
        async created() {
           if(this.disabledAllNotificationWeek == true) {
               this.zIndexValue = 'zIndexValueZero'
           }
            if(this.disabledAllNotificationWeek == false) {
                this.zIndexValue = 'zIndexValue'
            }

        },
        methods: {
            confirmRejectTimeUse() {
                this.timeUsePopup = {};
                this.isShowPopup = false;
            },
            saveTimeUse() {
                if(this.timeUsePopup.hourStart < 0 || this.timeUsePopup.minuteStart < 0 || this.timeUsePopup.hourEnd < 0 || this.timeUsePopup.minuteEnd < 0){
                    
                    return this.$vs.notify({
                        text: this.$t('weekTime.invalidTime'),
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger',
                    })
                }
                if (!this.timeUsePopup.value) return;

                try{
                    let from = parseInt(this.timeUsePopup.hourStart) * 60 + parseInt(this.timeUsePopup.minuteStart);
                    let to = parseInt(this.timeUsePopup.hourEnd) * 60 + parseInt(this.timeUsePopup.minuteEnd);
                    if(isNaN(from) || isNaN(to)){
                        throw '';
                    }
                    if(from >= to){
                        this.isShowPopup = false;
                        return
                    }
                    this.selectTimes[this.timeUsePopup.value.index] = this.selectTimes[this.timeUsePopup.value.index].filter(x => x.from != this.timeUsePopup.value.oldValue.from && x.to != this.timeUsePopup.value.oldValue.to);
                    this.selectTimes[this.timeUsePopup.value.index].push({from: from, to: to});
                    this.selectTimes[this.timeUsePopup.value.index] = this.handleValidateListTime(this.selectTimes[this.timeUsePopup.value.index]);
                    this.$eventBus.$emit(this.nameEventBus, {data: this.selectTimes});
                    this.isShowPopup = false;
                }catch(err) {
                    return this.$vs.notify({
                        text: this.$t('weekTime.invalidTime'),
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger',
                    })
                }

            },
            removeTimeUse() {
                if (!this.timeUsePopup.value) return;
                this.selectTimes[this.timeUsePopup.value.index] = this.selectTimes[this.timeUsePopup.value.index].filter(x => x.from != this.timeUsePopup.value.oldValue.from && x.to != this.timeUsePopup.value.oldValue.to);
                this.$eventBus.$emit(this.nameEventBus, {data: this.selectTimes});
                this.isShowPopup = false;
            },
            copyTimeUse(item) {
                this.arrCopy = item;
                return this.$vs.notify({
                    text: this.$t('weekTime.textCopy'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'success'
                })
            },
            pasteTimeUse(indexElement) {
                Object.assign(this.selectTimes[indexElement], this.arrCopy);
                this.$forceUpdate();
                this.$eventBus.$emit(this.nameEventBus, {data: this.selectTimes});
                return this.$vs.notify({
                    text: this.$t('weekTime.textPaste'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'success'
                })
            },
            mouseover(e, index) {
                if(this.isButtonDisable){
                    return;
                }
                let position = this.printPosition(e);
                if (e.target.getAttribute("class") == "row-progress") {
                    this.indexStartPaintProgress = index;
                    this.startPaintProgress = position.x;
                    let from = this.convertNumberToTime(position.x);
                    this.selectTimes[index].push({from: from, to: from, start: position.x});
                } else {
                    let position = this.printPosition(e, e.target.parentElement.parentElement);
                    let x = e.pageX;
                    let y = e.pageY;
                    let el = document.getElementsByClassName("vc-ui")
                    for (let i = 0; i < el.length; i++) {
                        el[i].style.left = (x - 250) + "px";
                        el[i].style.top = (y - 150) + "px";
                    }
                    this.setValuePopup(index, position.x);
                    this.isShowPopup = true;

                }

            },
            setValuePopup(index, poin) {
                let dataPoin = null;
                this.selectTimes[index].forEach(value => {
                    if (value.from <= this.convertNumberToTime(poin) && value.to >= this.convertNumberToTime(poin)) dataPoin = value;
                })
                this.timeUsePopup.value = {index: index, oldValue: dataPoin};
                this.timeUsePopup.hourStart = this.zeroPadding(Math.floor(dataPoin.from / 60), 2);
                this.timeUsePopup.minuteStart = this.zeroPadding(Math.floor(dataPoin.from % 60), 2);
                this.timeUsePopup.hourEnd = this.zeroPadding(Math.floor(dataPoin.to / 60), 2);
                this.timeUsePopup.minuteEnd = this.zeroPadding(Math.floor(dataPoin.to % 60), 2);
            },
            zeroPadding: function (input, length) {
                return (input + "").padStart(length, 0);
            },
            mousemove(e, index) {
                if (e.target.getAttribute("class") != "row-progress") return;
                if (index && index == this.indexStartPaintProgress) {
                    let oldEls = document.getElementsByClassName(`bl-time-progress-${this.indexStartPaintProgress}`);
                    for (let i = 0; i < oldEls.length; i++) {
                        oldEls[i].style.zIndex = -1;
                    }
                    let position = this.printPosition(e);
                    this.progressFocus = {from: 0, to: 0};
                    if (position.x > this.startPaintProgress) {
                        this.progressFocus.from = this.startPaintProgress;
                        this.progressFocus.to = position.x;
                    } else {
                        this.progressFocus.from = position.x;
                        this.progressFocus.to = this.startPaintProgress;
                    }
                    let from = this.convertNumberToTime(this.progressFocus.from);
                    let to = this.convertNumberToTime(this.progressFocus.to);
                    let selectTimeIndex = this.selectTimes[index].findIndex(x => x.start == this.startPaintProgress);
                    this.selectTimes[index][selectTimeIndex] = {from: from, to: to, start: this.startPaintProgress};
                    this.$forceUpdate();
                }
            },
            mouseup() {
                if (!this.startPaintProgress && !this.indexStartPaintProgress) return;
                let oldEls = document.getElementsByClassName(`bl-time-progress-${this.indexStartPaintProgress}`);
                for (let i = 0; i < oldEls.length; i++) {
                    oldEls[i].style.zIndex = 1;
                }
                let selectTimeIndex = this.selectTimes[this.indexStartPaintProgress].findIndex(x => x.start);
                let selectTime = this.selectTimes[this.indexStartPaintProgress].find(x => x.start);
                this.selectTimes[this.indexStartPaintProgress][selectTimeIndex] = {
                    from: selectTime.from,
                    to: selectTime.to
                };
                this.selectTimes[this.indexStartPaintProgress] = this.handleValidateListTime(this.selectTimes[this.indexStartPaintProgress]);
                this.$eventBus.$emit(this.nameEventBus, {data: this.selectTimes});
                this.startPaintProgress = null;
                this.indexStartPaintProgress = null;
            },
            getPosition(e, target) {
                let targetClick = e.target;
                if (target) targetClick = target;
                let rect = targetClick.getBoundingClientRect();
                let x = e.clientX - rect.left;
                let y = e.clientY - rect.top;
                return {x, y}
            },
            printPosition(e, target) {
                let position = this.getPosition(e, target);
                return position;
            },

            handleValidateListTime(listTime) {
                let afterSort = this.sortListTime(listTime)
                let result = this.handleValid(afterSort)
                return result;
            },
            handleValid(listTime) {
                if (listTime.length <= 1) {
                    return listTime
                }
                let listResult = [listTime[0]]
                let index = 0
                for (let i = 1; i < listTime.length; i++) {
                    let old = listResult[index]
                    let current = listTime[i]
                    if (old.from <= current.from && old.to >= current.from) {
                        let maxTo = Math.max(old.to, current.to)
                        old.to = maxTo
                    } else {
                        listResult.push(current)
                        index++
                    }
                }
                return listResult
            },
            sortListTime(list) {
                return list.sort((a, b) => (a.from > b.from) ? 1 : -1)
            },
            convertNumberToTime(number) {
                return Math.round(number * 2);
            },
        },
        mounted() {
        }

    }
</script>


<style>
    .bl-time-clock-v2 .bl-time-progress {
        position: absolute;
        top: -1px;
        background-color: rgba(var(--vs-primary), 1) !important;
        cursor: pointer;
        height: 25px;
        border: 1px solid rgba(var(--vs-primary), 1) !important;
    }

    .wizard-btn {
        font-weight: normal !important;
    }
</style>
<style scoped>
    #week-timeline span, #week-timeline label {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;
    }

    .zIndexValue{
      z-index: -1
    }
    .zIndexValueZero {
      z-index: 0
    }
    .vc-ui {
        border: 1px solid;
        padding: 10px 10px 5px 10px;
        align-items: center;
        border-radius: 5px;
        width: 365px;
        border-color: transparent;
        background-color: #fff;
        -webkit-box-shadow: 0 4px 10px rgb(0 0 0 / 20%);
        box-shadow: 0 4px 10px rgb(0 0 0 / 20%);
        max-width: inherit;
        min-width: 100px;
        border-radius: 5px;
        z-index: 51100;
        position: fixed;
    }

    .width-59 {
        width: 59px;
    }

    .label-weak {
        width: 100px
    }

    .bl-time-clock-v2 .row-progress {
        width: 100%;
        height: 25px;
        border: 1px solid #172b4d;
        position: relative;
    }

    .d-flex {
        display: -webkit-box !important;
        display: flex !important;
    }

    .bl-time-clock-v2 .row-number .colm {
        font-size: 7px;
        width: 30px;
        color: #172b4d;
        position: relative;
    }

    .bl-time-clock-v2 .row-number span {
        text-align: center;
        padding-bottom: 4px;
        position: relative;
        width: 10px;
        position: absolute;
        left: -5px;
        bottom: 0;
    }

    .bl-time-clock-v2 .row-number span:after {
        display: block;
        content: "";
        width: 1px;
        height: 3px;
        background-color: #172b4d;
        position: absolute;
        left: 5px;
        bottom: 0;
    }
</style>
